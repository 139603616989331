<template>
  <div class="activityDialog">
    <el-dialog top="3%" width="50%" :title="dialogTitle" :visible.sync="visible" :close-on-click-modal="false"
      :before-close="closeEvent">
      <el-form ref="dialogForm" :model="form" label-width="120px">
        <el-row>
          <el-col :span="12">
            <el-form-item label="文章名称" prop="articleTitle">
              <el-input v-model="form.articleTitle" placeholder="请输入文章名称"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="文章排序" prop="articleSort">
              <el-input v-model="form.articleSort" placeholder="文章排序"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="文章封面" prop="articleImg">
              <el-upload class="avatar-uploader" :headers="headers" :data="{width:750}" :action="'/api/admin/public/uploadImg/articleImg'" :show-file-list="false"
                :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
                <img v-if="form.articleImg" :src="form.articleImg" class="avatar" />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="文章简介" prop="articleDescribe">
              <el-input type="textarea" :rows="4" placeholder="请输入文章简介" v-model="form.articleDescribe">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="文章内容" prop="articleBody">
              <vue-editor useCustomImageHandler @image-added="handleImageAdded" v-model="form.articleBody"></vue-editor>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeEvent()">取 消</el-button>
        <el-button type="primary" @click="submitData()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";
import axios from "axios";
import config from "@/assets/api/config.js";
export default {
  components: { VueEditor },
  data() {
    return {
      visible: false,
      dialogTitle: "新增文章",
      form: {
        "articleBody": "",
        "articleDescribe": "",
        "articleImg": "",
        "articleSort": "",
        "articleTitle": "",
      },
      headers: {
        token: localStorage.getItem('token')
      },
      config,
    };
  },
  methods: {
    handleImageAdded: function (file, Editor, cursorLocation, resetUploader) {

      var formData = new FormData();
      formData.append("file", file);
      axios({
        url: "/api/admin/public/upload/articleContent",
        method: "POST",
        data:formData,
        headers: {
          'token': localStorage.getItem('token')
        }
      })
        .then(res => {
          console.log(res);
          const url = res.data.data.downloadUrl; // Get url from response
          Editor.insertEmbed(cursorLocation, "image", url);
          resetUploader();
        })
        .catch(err => {
          console.log(err);
        });
    },
    handleAvatarSuccess(res, file) {
      console.log(res, file);
      this.form.articleImg = res.data.downloadUrl;
    },
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 10;

      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 10MB!");
      }
      return isLt2M;
    },
    submitData() {
      let params = JSON.parse(JSON.stringify(this.form));
      let url = "";
      if (params.articleId) {
        //修改
        url = "api/admin/sys/main/updateNews";
      } else {
        url = "api/admin/sys/main/news";
      }

      this.getAjax(url, params, "post").then(() => {
        this.$message.success("操作成功");
        this.closeEvent();
        this.$emit("search");
      });
    },
    closeEvent() {
      this.visible = false;
      this.dialogTitle = "新增文章";
      this.form = {};
    },
    getInfo(info) {
      this.dialogTitle = "编辑文章";
      this.form = info;
      this.visible = true;
    },
  },
  mounted() { },
};
</script>

<style lang="less">
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9 !important;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
